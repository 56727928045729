import { gql } from '@apollo/client';

const FRAGMENT_SHOP_SERVICE_LOCATION = gql`
  fragment FRAGMENT_SHOP_SERVICE_LOCATION on ShopServiceLocation {
    id
    createdAt
    updatedAt
    name
    medias {
      src
    }
    active
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        serviceLocations(cursor: $cursor, limits: $limits, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_SHOP_SERVICE_LOCATION
          }
        }
      }
    }
  }
  ${FRAGMENT_SHOP_SERVICE_LOCATION}
`;
